// This file is used by our deployment pipeline. The placeholders will be replaced by actual values from
// variables in Github Environments.

import type { Environment } from './environment.type';

export const environment: Environment = {
  production: true,
  appUiUrl: 'https://staging.hs.squadbox.ai',
  appApiUrl: 'https://api.staging.hs.squadbox.ai',
  refreshTokensTimerDue: +'300000',
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore Will be replaced by valid values during deployment
  speechToTextProvider: 'azure',
  azureSpeechToTextRegion: 'canadacentral',
  sentry: {
    dsn: 'https://c7b7c43c20b97fdf8360460bfe7e50ee@o936403.ingest.us.sentry.io/4508202231726080',
    environment: 'hs-staging',
    release: 'squadbox-1cfaeceb199c05f9d7731f64c18ed95d76c51e47',
  },
  launchDarkly: {
    envKey: '67250f2d1a3154080d025250',
    context: {
      kind: 'multi',
      user: {
        anonymous: true,
      },
      client: {
        key: 'hs',
        name: 'hs',
      },
      device: {
        anonymous: true,
      },
      browser: {
        anonymous: true,
      },
    },
    options: {
      bootstrap: 'localStorage',
      application: {
        id: 'squadbox-ui-hs-staging',
        version: 'squadbox-1cfaeceb199c05f9d7731f64c18ed95d76c51e47',
      },
    },
  },
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore Will be replaced by valid values during deployment
  defaultLanguage: 'en',
  assistantPromptMaxLength: +'10000',
};
